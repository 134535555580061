.content-wrapper {
  display: flex;
}
.sidebar {
  width: 250px;
  position: sticky;
  top: 0;
  background: #230752;
  height: 100vh;
  text-align: center;
  color: white;
}

.sidebar .logo {
  border-radius: 50%;
  border: solid 4px white;
}
.online-status {
  display: flex;
  align-content: stretch;
  justify-content: center;
}
.online-status img {
  width: 20px;
}
.sidebar-header {
  margin: 10px;
  border: solid;
  padding: 10px;
}
.link-list {
  text-align: left;
  margin: 10px;
  text-transform: uppercase;
}
ul.links li {
  margin: 20px 0;
  background: #ed7507;
  padding: 10px 4px;
  border: solid 1px #ed7507;
  cursor: pointer;
}
ul.links li a {
  color: white;
}
ul.links li:hover {
  margin: 20px 0;
  background: #120130;
  padding: 10px 4px;
  cursor: pointer;
  font-weight: bold;
  border: solid 1px #ed7507;
}
.main {
  background: #120130;
  width: calc(100% - 250px);
}
.main-content {
  max-width: 760px;
  margin: 0 auto;
}

.package-form {
  display: flex;
  flex-direction: column;
}

.package-form input {
  height: 40px;
  border: navajowhite;
  padding: 10px;
  font-size: 16px;
  text-align: justify;
  margin: 10px 0;
}
.package-form label {
  color: white;
}
.ql-snow * {
  box-sizing: border-box;
  background: white;
  font-size: 16px;
  font-weight: 400;
}

.not-auth {
  width: 60%;
  margin: 25px auto;
  text-align: center;
}

.single-image {
  width: 70px;
  height: 70px;
}

input#img_link {
  width: 100%;
  margin-top: 20px;
  height: 30px;
  border-radius: 10px;
  padding: 10px;
}
.image-form {
  text-align: center;
}
button.btn_add {
  padding: 10px 30px;
  margin-top: 12px;
  border: 0;
  width: 300px;
  border-radius: 10px;
  background: #ed7507;
  background-color: rgb(237, 117, 7);
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.gallery-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
.single-image {
  width: 70px;
  height: 70px;
}
.gallery-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
#gallery {
  line-height: 0;
  -webkit-column-count: 4; /* split it into 5 columns */
  -webkit-column-gap: 5px; /* give it a 5px gap between columns */
  -moz-column-count: 5;
  -moz-column-gap: 5px;
  column-count: 4;
  column-gap: 5px;
}
#gallery img:hover {
  filter: grayscale(100%);
}
#gallery img {
  cursor: pointer;
  width: 100% !important;
  height: auto !important;
  margin-bottom: 5px; /* to match column gap */
}
@media (max-width: 1200px) {
  #gallery {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  #gallery {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  #gallery {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  #gallery {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
