/* about us */

a {
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.section-header h3 {
  font-size: 36px;
  color: #000000;
  text-align: center;
  font-weight: 700;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.section-header p {
  text-align: center;
  margin: auto;
  font-size: 15px;
  padding-bottom: 60px;
  color: #000000;
  width: 50%;
}

@media (max-width: 767px) {
  .section-header p {
    width: 100%;
  }
}

#services {
  padding: 60px 0 40px 0;
}

#services .box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
}
.box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 10px 40px 10px;
  background: #fff;
  box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
  transition: all 0.3s ease-in-out;
  text-align: center;
}
/* .box:hover {
  transform: scale(1.1);
} */
/* #services .box:hover {
  transform: scale(1.1);
} */

#services .icon {
  margin: 0 auto 15px auto;
  padding-top: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

#services .icon .service-icon {
  font-size: 36px;
  line-height: 1;
}

#services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#services .title a {
  color: #111;
}

#services .box:hover .title a {
  color: #c59c35;
}
#services .box:hover .title a:hover {
  text-decoration: none;
}
#services .description {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
  text-align: left;
}
.history-info h2 {
  color: #000000;
  font-size: 30pt;
  text-align: left;
}
.history-info {
  margin-bottom: 10px;
}
.history-info p {
  color: #000000;
  font-size: 14pt;
  padding: 10px 0;
  text-align: left;
}

.history-info a {
  background-color: #0c4c91;
  color: white;
  width: 209px;
  text-align: center;
  padding: 14px 9px 15px 12px;
  font-size: 19pt;
  box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
}

.history-info a:hover {
  background: #404140;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  transform: translateY(10px);
}
.filter-form .form-group select {
  width: 100%;
  text-align: center;
  position: relative;
  display: initial;
  height: auto;
  color: #222;
  font-size: 14px;
  line-height: 38px;
  padding: 10px 30px;
  margin: 0;
  border: 1px solid #ddd;
  background-color: #fff;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  /* -webkit-transition: all 0.3s ease; */
}

.form-group select {
  width: 47%;
  text-align: center;
  position: relative;
  display: initial;
  height: 40px;
  color: #222;
  font-size: 14px;
  line-height: 38px;
  padding: 10px 10px;
  margin: 20px 7px 0px 0px;
  border: 1px solid #ddd;
  background-color: #fff;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.tabs input[type="radio"] {
  display: none;
}
.tabs label {
  transition: background 0.4s ease-in-out, height 0.2s linear;
  display: inline-block;
  cursor: pointer;
  color: #222222;
  width: 20%;
  height: 3em;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: #fcfcfc;
  text-align: center;
  line-height: 3em;
}
.tabs label:last-of-type {
  border-bottom: none;
}
.tabs label:hover {
  background: #535353;
  color: #ffffff;
}
@media screen and (max-width: 1600px) {
  .tabs label {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .tabs label {
    width: 20%;
  }
}
@media screen and (max-width: 600px) {
  .tabs label {
    width: 100%;
    display: block;
    border-bottom: 2px solid #c7c6c4;
    border-radius: 0;
  }
}
@media screen and (max-width: 600px) {
  .tabs {
    margin: 0;
  }
}

#tab1:checked + label,
#tab2:checked + label,
#tab3:checked + label,
#tab4:checked + label {
  background: #222222;
  color: #ffffff;
}

.tab-content {
  position: absolute;
  top: -9999px;
  padding: 10px;
}

.tab-content-wrapper {
  background: #fcfcfc;
  border-top: #ff6603 5px solid;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
}
@media screen and (max-width: 600px) {
  .tab-content-wrapper,
  .tab1-content-wrapper {
    border: none;
    border-radius: 0;
  }
}

#tab1:checked ~ .tab-content-wrapper #tab-content-1,
#tab2:checked ~ .tab-content-wrapper #tab-content-2,
#tab3:checked ~ .tab-content-wrapper #tab-content-3,
#tab4:checked ~ .tab-content-wrapper #tab-content-4 {
  position: relative;
  top: 0px;
}

li,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
@media (max-width: 760px) {
  .contact-page-section .form-column {
    width: 100% !important;
  }
}
.contact-form .row {
  display: block;
}
.sec-title {
  position: relative;
  padding-bottom: 40px;
}
.sec-title .title {
  position: relative;
  color: #fe9859;
  font-size: 18px;
  font-weight: 700;
  padding-right: 50px;
  margin-bottom: 15px;
  display: inline-block;
  text-transform: capitalize;
}
.sec-title .title:before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 7px;
  width: 40px;
  height: 1px;
  background-color: #bbb;
}
.sec-title h2 {
  position: relative;
  color: #252525;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.5em;
  display: block;
}
.sec-title.light h2 {
  color: #fff;
}
.contact-page-section {
  position: relative;
  padding-top: 55px;
  padding-bottom: 55px;
}
.contact-page-section .inner-container {
  position: relative;

  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
}
.contact-page-section .form-column {
  position: relative;
  width: 90%;
}
.contact-page-section .form-column .inner-column {
  position: relative;
  padding: 60px 15px 30px;
  background-color: #fff;
}
.contact-page-section .info-column {
  position: relative;
  background-color: grey;
}
.contact-page-section .info-column .inner-column {
  position: relative;
  padding: 60px 35px;
}
.contact-page-section .info-column h2 {
  position: relative;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.4em;
  margin-bottom: 45px;
}
.contact-page-section .info-column .list-info {
  position: relative;
  margin-bottom: 60px;
}
.contact-page-section .info-column .list-info li {
  position: relative;
  margin-bottom: 25px;
  font-size: 18px;
  color: #fff;
  line-height: 1.8em;
  padding-left: 45px;
}
.contact-page-section .info-column .list-info li:last-child {
  margin-bottom: 0;
}
.contact-page-section .info-column .list-info li i {
  position: absolute;
  left: 0;
  top: 8px;
  color: #fff;
  font-size: 30px;
}
.contact-form {
  position: relative;
}
.contact-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 45px;
  color: #222;
  font-size: 14px;
  line-height: 38px;
  padding: 10px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
  border-color: #ff6600;
}
.contact-form textarea {
  height: 250px;
  resize: none;
}
.contact-form .theme-btn {
  font-size: 16px;
  box-shadow: rgb(38 57 77) 0px 20px 30px -10px;
  font-weight: 700;
  margin-top: 10px;
  text-transform: capitalize;
  padding: 16px 39px;
  border: 2px solid #ff6600;
  font-family: Arimo, sans-serif;
  background: #ff6600;
  display: inline-block;
  position: relative;
  line-height: 24px;
  cursor: pointer;
  color: #fff;
}
.contact-form .theme-btn:hover {
  color: #00b8ca;
  border-color: #00b8ca;
  background: 0 0;
}
.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: red !important;
}
.contact-form label.error {
  display: block;
  line-height: 24px;
  padding: 5px 0 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  color: red;
  font-weight: 500;
}
.social-icon-four {
  position: relative;
}
.social-icon-four li {
  position: relative;
  margin-right: 18px;
  display: inline-block;
}
.social-icon-four li.follow {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  display: block;
  margin-bottom: 20px;
}
.social-icon-four li a {
  position: relative;
  font-size: 20px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social-icon-four li a:hover {
  color: #222;
}

@media (max-width: 768px) {
  col-md-6 {
    width: 50% !important;
    float: left !important;
  }
}
