.empty-space {
  height: 100vh;
}
.form-container {
  width: 35%;
  padding: 20px;
  margin: 0 auto;
  margin-top: 10%;
  justify-content: space-around;
  box-shadow: 0px 14px 17px -3px rgb(0 0 0 / 39%);
}
.form-content {
  display: flex;
  flex-direction: column;
}
.form-content input {
  height: 40px;
  border: navajowhite;
  padding: 10px;
  font-size: 16px;
  text-align: justify;
  margin: 10px 0;
}
